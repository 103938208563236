/* eslint-disable no-unused-vars */
import apiService from '@/utils/api-client'
import config from '@/utils/appConfig'
import store from '@/store'

export default {
  // eslint-disable-next-line no-unused-vars
  getCounterpartyList: async function (
    { commit },
    {
      title = undefined,
      from = undefined,
      to = undefined,
      coin = undefined,
      $skip = undefined,
      $limit = undefined,
      archived = undefined,
    },
  ) {
    try {
      return apiService.get('report/counter-party', {
        params: { from, to, $skip, $limit, title, archived },
      })
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  getCounterpartyReportById: async function (
    { commit },
    { enqId, searchingParams },
  ) {
    try {
      return apiService.get(`report/counter-party/${enqId}`)
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async createCounterpartyReport(
    { commit },
    {
      title,
      directions,
      direction,
      address,
      tx_hash,
      token = undefined,
      maxDepth = undefined,
      thresholdAmount = undefined,
      ...extraParams
    },
  ) {
    try {
      return apiService.post('report/counter-party', {
        ...extraParams,
        title,
        directions,
        direction,
        address,
        tx_hash,
        token,
        maxDepth,
        thresholdAmount,
      })
    } catch (error) {
      console.error(error)
    } finally {
      await store.dispatch('user/getMe')
    }
  },
  // eslint-disable-next-line no-unused-vars
  async editCounterpartyReport(
    { commit },
    { enqId, title = undefined, archived = undefined, kind = undefined },
  ) {
    try {
      return apiService.patch(`report/counter-party/${enqId}`, {
        title,
        archived,
        kind,
      })
    } catch (error) {
      console.error(error)
    } finally {
      await store.dispatch('user/getMe')
    }
  },

  // eslint-disable-next-line no-unused-vars
  async deleteCounterpartyReport({ commit }, { id }) {
    try {
      return apiService.delete(`report/counter-party/${id}`)
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  counterpartyReportToArchive({ commit }, { ids, status }) {
    return apiService.put(
      'report/counter-party',
      { ids, status: String(status) },
      { baseURL: config.VUE_APP_API_USERS_URL },
    )
  },

  async getCounterpartySources(
    { commit },
    {
      enqId,
      directionUp,
      token = '',
      owner = undefined,
      fundsName = undefined,
      fundsRating = undefined,
      $skip = 0,
      $limit = 10,
      $sort = undefined,
    },
  ) {
    try {
      const data = apiService.get(`report/counter-party/${enqId}/sources`, {
        params: {
          enqId,
          directionUp,
          token,
          $skip,
          $limit,
          owner,
          funds: {
            name: fundsName,
            rating: fundsRating,
          },
          $sort,
        },
      })
      return data
    } catch (error) {
      console.error(error)
    }
  },
}
