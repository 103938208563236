export default {
  activeEntity: null,
  entityProvidedServiceList: {
    items: [],
  },
  entityPaymentServiceList: {},
  entityPaymentMethodList: {},
  entityBanksList: {},
  entityTagsList: {},
  entityTypesList: {},
  entityStatusList: [
    {
      value: 'active',
      name: 'Active',
    },
    {
      value: 'suspended',
      name: 'Suspended',
    },
    {
      value: 'pending',
      name: 'Pending Approval',
    },
  ],
  entityKYCList: [
    {
      value: 'required',
      name: 'Required',
    },
    {
      value: 'not_required',
      name: 'Not Required',
    },
    {
      value: 'partially_required',
      name: 'Partially Required',
    },
  ],
  isFiatCurrencyTradingList: [
    {
      value: true,
      name: 'Yes',
    },
    {
      value: false,
      name: 'No',
    },
  ],
  isPrivacyCoinsSupportedList: [
    {
      value: true,
      name: 'Yes',
    },
    {
      value: false,
      name: 'No',
    },
  ],
  entityPaymentTypesList: [
    {
      value: 'bank_transfer',
      name: 'Bank Transfer',
    },
    {
      value: 'credit_card',
      name: 'Credit Card',
    },
    {
      value: 'cash',
      name: 'Cash',
    },
    {
      value: 'wallet_service',
      name: 'Wallet Service',
    },
  ]
}
