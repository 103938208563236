import { getManualFiltersFields } from '@/pages/alerts/models/alerts-controls'
import { allBlockchainCoin } from '@/assets/static/currency-list'

export default {
  unreadNotificationCount: 0,
  activeManualFilters: getManualFiltersFields(),
  activeSmartFilters: null,
  activeBlockchain: allBlockchainCoin,
  isAISort: false,
}
