import { getManualFiltersFields } from '@/pages/alerts/models/alerts-controls'
export default {
  SET_NOTIFICATION_COUNT(state, data) {
    state.unreadNotificationCount = data
  },
  SET_ACTIVE_MANUAL_FILTERS(state, payload) {
    state.activeManualFilters = payload
  },
  SET_ACTIVE_SMART_FILTERS(state, payload) {
    state.activeSmartFilters = payload
  },
  SET_INITIAL_ACTIVE_MANUAL_FILTERS(state) {
    state.activeManualFilters = getManualFiltersFields()
  },
  SET_ACTIVE_BLOCKCHAIN(state, payload) {
    state.activeBlockchain = payload
  },
  SET_IS_AI_SORT(state, payload) {
    state.isAISort = payload
  },
}
