export const toQueryParams = (obj, prefix = '') => {
  return Object.keys(obj)
    .map(key => {
      const value = obj[key]
      const prefixedKey = prefix ? `${prefix}[${key}]` : key

      if (Array.isArray(value)) {
        return value
          .map(
            val =>
              `${encodeURIComponent(prefixedKey)}=${encodeURIComponent(val)}`,
          )
          .join('&')
      } else if (typeof value === 'object' && value !== null) {
        return toQueryParams(value, prefixedKey)
      } else {
        return `${encodeURIComponent(prefixedKey)}=${encodeURIComponent(value)}`
      }
    })
    .join('&')
}
