import config from "@/utils/appConfig";
export default {
    history: [],
	isHash: false,
	isAddress: false,
	searchValue: null,
	searchType: '',
	canUndo: false,
	canRedo: false,
	coinType: 'btc',
	coinData: {
		key: 'btc',
		family: 'btc',
		path: 'tx',
		label: 'BTC',
		baseUrl: config.VUE_APP_API_BASE_URL,
	},
	currencyList: [],
	essentialCurrencyList: [],
	oldNewChain: {
		oldChain: {},
		newChain: {},
	},
	activeLayout: null,
	layoutsList: [
		{
			label: "Klay",
			value: "klay"
		},
		{
			label: "Cola",
			value: "cola"
		},
		{
			label: "Circle",
			value: "circle"
		},
		{
			label: "Hierarchy",
			value: "breadthfirst"
		},
	],
	rightToolbarState: null,
}
