import apiService from '@/utils/api-client'
import { sendFormatter } from "@/utils/text-formatter";
import analytics from "@/store/modules/analytics";

export default {
  // eslint-disable-next-line no-unused-vars
  async getTypes({ commit }, { name, sortField, count = 1000, skip, sortOrder, search, profileId, isLabeling = false, ids, dry }) {
    try {
      const data = apiService.get(`labeling/getTypes`, { params: { name, sortField, count, skip, sortOrder, search, profileId, ids, dry }, baseURL: isLabeling ? analytics.state.coinData.profilesServiceUrl : null })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async addType({ commit }, { name, score }) {
    const formattedName = sendFormatter(name)

    try {
      return apiService.post(`labeling/addType`, { name: formattedName, score })
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async mergeTypes({ commit }, { toType, fromTypes, score, skipCheck = false, baseUrl = '' }) {
    const formattedToType = sendFormatter(toType)
    const formattedFromType = fromTypes.map(type => sendFormatter(type))

    try {
      return apiService.post(`labeling/mergeTypes`, { toType: formattedToType, fromTypes: formattedFromType, score, skipCheck }, { baseURL: baseUrl || null })
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async editType({ commit }, { pre, name, newName, score, skipCheck = false, profileId = null, isLabeling = false, id, baseUrl }) {
    const formattedName = sendFormatter(name)
    const formattedNewName = sendFormatter(newName)

    try {
      const data = apiService.post(`${isLabeling ? '/profiles/labeling/editType' : 'labeling/editType'}`, { pre, name: formattedName, newName: formattedNewName, score, skipCheck, profileId, id }, { baseURL: baseUrl || null })
      return data
    } catch (error) {
      console.error(error)
    }
  },
  deleteType(ctx, { names, ids, skipCheck, baseUrl = ''  }) {
    const formattedNames = names.map(name => sendFormatter(name))

    return apiService.delete(`labeling/deleteType`, { data: { names: formattedNames, ids, skipCheck }, baseURL: baseUrl || null });
  },
  // eslint-disable-next-line no-unused-vars
  resetScoreType({ commit }, { name, profileId = null, id, baseUrl }) {
    try {
      const data = apiService.post(`labeling/resetScoreType`, { name, profileId, id }, { baseURL: baseUrl || analytics.state.coinData.profilesServiceUrl })
      return data
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  resetScoreTag({ commit }, { name, profileId = null, id, baseUrl }) {
    try {
      const data = apiService.post(`labeling/resetScoreTag`, { name, profileId, id }, { baseURL: baseUrl || analytics.state.coinData.profilesServiceUrl })
      return data
    } catch (error) {
      console.error(error)
    }
  },
}
