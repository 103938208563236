import apiService from '@/utils/api-client'

export default {
  // eslint-disable-next-line no-unused-vars
  async getSubscriptions({ commit }, { skip = 0, count = 10, address = undefined }) {
    try {
      return apiService.get('subscription', {
        params: {
          address,
          $skip: skip,
          $limit: count,
        }
      })
    } catch (error) {
      console.error(error)
    }
  },
  async getMetaOfAddresses({ commit }, { address = undefined }) {
    try {
      return apiService.get('/scorer', {
        params: {
          address,
          $project: {
            address: 1,
            firstSeen: 1,
            lastSeen: 1,
            txCount: 1,
            balance: 1,
            amountReceived: 1,
            balanceData: 1,
            amountReceivedData: 1,
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getAddressesMonitoring({ commit }, { skip = 0, count = 10, address = undefined }) {
    try {
      return apiService.get('monitoring/addressToMonitor', {
        params: {
          skip,
          count,
          address
        }
      })
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getListSubscriptions({ commit }, { skip = 0, count = 10, address = undefined }) {
    try {
      return apiService.get('monitoring/listSubscriptions', {
        params: {
          skip,
          count,
          address
        }
      })
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async addToMonitoring({ commit }, { address, file, checkLastTen, blockchain = 'BTC', token = [] }) {
    try {
      return apiService.post('monitoring/addressToMonitor', {
          address,
          token: token.length ? token : undefined,
          file,
          checkLastTen,
          blockchain
        })
    } catch (error) {
        console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async deleteAddressFromMonitoring({ commit }, { address }) {
    try {
      const data = apiService.delete(`monitoring/addressToMonitor/${address}`)
      return data
    } catch (error) {
      console.error(error)
    }
  },
}
