import apiService from '@/utils/api-client'
import { sendFormatter } from "@/utils/text-formatter";
import analytics from "@/store/modules/analytics";

export default {
	// eslint-disable-next-line no-unused-vars
  updateWallet({ commit }, data) {
    return apiService.put(`/labeling/updateWallet`, data)
  },
	// eslint-disable-next-line no-unused-vars
  updateCluster({ commit }, data) {
    return apiService.put('/labeling/updateCluster', data)
  },

  // eslint-disable-next-line no-unused-vars
  async getUploadConflicts({ commit }, { type, count, skip, address = undefined, cluster = undefined, from = undefined, to = undefined }) {
    try {
      const data = apiService.get(`labeling/getUploadConflicts`, { params: {
        type,
        count,
        skip,
        address: address ? address : undefined,
        cluster: cluster ? cluster : undefined,
        to,
        from
      }
      })
      return data
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getConflictsTotal({ commit }, { type }) {
    try {
      const data = apiService.get(`labeling/getConflictsTotal`, { params: { type } })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async resolveConflict({ commit }, { type, id, index, skipCheck = false }) {
    try {
      const data = apiService.post(`labeling/resolveConflict`, { type, id, index, skipCheck })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async deleteConflict({ commit }, { type, id, index }) {
    try {
      const data = apiService.delete(`labeling/deleteConflict`, { data: { type, id, index } })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  // FOR LABELING
  // eslint-disable-next-line no-unused-vars
  async getTags({ commit }, { name, sortField, count = 1000, skip, sortOrder, search, profileId, isLabeling = false, ids, dry } ) {
    try {
      const data = apiService.get(`labeling/getTags`, { params: { name, sortField, count, skip, sortOrder, search, profileId, ids, dry }, baseURL: isLabeling ? analytics.state.coinData.profilesServiceUrl : null })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async getOwners({ commit }, { owner, $limit, baseUrl } ) {
    try {
      const data = apiService.get(`labeling/findOwners`, { params: {
        owner: owner || undefined,
        $limit: $limit || undefined
      }, baseURL: baseUrl })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async getClusterInfo({ commit }, clusterId) {
    try {
      const data = apiService.get(`explorer/getClusterInfo/${clusterId}`)
      return data
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async getAddressTags({ commit }, address) {
    try {
      const data = apiService.get(`labeling/getAddressTags/${ address }`)
      return data
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async getClusterConflicts({ commit }, { count, skip }) {
    try {
      const data = apiService.get(`labeling/mergedClusterInfo`, { params: { count, skip } })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async resolveClusterConflicts({ commit }, cluster) {
    try {
      const data = apiService.get(`labeling/resolveMergedCluster/${cluster}`)
      return data
    } catch (error) {
      console.error(error)
    }
  },

  async generateClusterInfo() {
    try {
      const data = apiService.get(`labeling/generateClusterInfo`)
      return data
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async addTag({ commit }, { name, score, metadata }) {
    const formattedName = sendFormatter(name)

    try {
      return apiService.post(`labeling/addTag`, { name: formattedName, score, metadata })
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async editTag({ commit }, { pre, name, newName, score, metadata, skipCheck = false, profileId = null, isLabeling = false, id, baseUrl = analytics.state.coinData.baseUrl }) {
    const formattedName = sendFormatter(name)
    const formattedNewName = sendFormatter(newName)

    try {
      const data = apiService.post(`${isLabeling ? '/profiles/labeling/editTag' : 'labeling/editTag'}`, { name: formattedName, newName: formattedNewName, score, metadata, skipCheck, profileId, id, pre }, { baseURL: baseUrl })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  deleteTag(ctx, { names, skipCheck = false, ids, baseUrl = '' }) {
    const formattedNames = names.map(name => sendFormatter(name))

    return apiService.delete(`labeling/deleteTag`, { data: { names: formattedNames, skipCheck, ids }, baseURL: baseUrl || null });
  },

  deleteAddressTag(ctx, { name, address }) {
    const formattedName = sendFormatter(name)

    return apiService.delete(`labeling/deleteAddressTag/${address}/${formattedName}`)
  },

  deleteClusterTag(ctx, { name, address }) {
    const formattedName = sendFormatter(name)

    return apiService.delete(`labeling/deleteClusterTag/${address}/${formattedName}`)
  },
}
