const highPriority = ({ value = false } = {}) => {
  return {
    label: 'High',
    kind: 'high',
    key: 100,
    value,
  }
}
const mediumPriority = ({ value = false } = {}) => {
  return {
    label: 'Medium',
    kind: 'medium',
    key: 50,
    value,
  }
}
const lowPriority = ({ value = false } = {}) => {
  return {
    label: 'Low',
    kind: 'low',
    key: 0,
    value,
  }
}

const sanctionsLabels = [
  'Sanctioned individual',
  'Sanctioned exchange',
  'OFAC sanctioned wallet',
  'OFAC sanctioned exchange',
]
const fraudScamLabels = [
  'Fraud',
  'Scam token deployer',
  'Scam token',
  'Scam online wallet',
  'Scam',
  'Reported scam',
  'Phishing scam',
  'NFT scam',
  'Malware scam',
]

const getManualFiltersFields = () => {
  return {
    priorityList: [highPriority(), mediumPriority(), lowPriority()],
    txAmountRange: {
      from: '',
      to: '',
    },
    triggeredAmountRange: {
      from: '',
      to: '',
    },
    scoreRange: {
      from: '',
      to: '',
    },
    listDates: [],
    depth: {
      from: '',
      to: '',
    },
    labels: [],
  }
}

const smartFilterSelectOptions = [
  {
    ...getManualFiltersFields(),
    priorityList: [
      highPriority({ value: true }),
      mediumPriority({ value: true }),
      lowPriority({ value: true }),
    ],
    txAmountRange: {
      from: '5',
      to: '1000',
    },
    depth: {
      from: '1',
      to: '9',
    },
  },
  {
    ...getManualFiltersFields(),
    priorityList: [
      highPriority(),
      mediumPriority({ value: true }),
      lowPriority({ value: true }),
    ],
    txAmountRange: {
      from: '3000',
      to: '5000',
    },
    depth: {
      from: '5',
      to: '',
    },
  },
]

const getSmartFiltersFields = () => {
  return [
    {
      key: 'multiple_selection',
      multiple: true,
      value: null,
      options: smartFilterSelectOptions,
    },
    {
      key: 'sanctions',
      isActive: true,
      value: {
        ...getManualFiltersFields(),
        labels: sanctionsLabels,
      },
    },
    {
      key: 'fraud_scam',
      isActive: false,
      value: {
        ...getManualFiltersFields(),
        labels: fraudScamLabels,
      },
    },
    {
      key: 'more_than_15000',
      isActive: false,
      value: {
        ...getManualFiltersFields(),
        txAmountRange: {
          from: '15000',
          to: '',
        },
      },
    },
    {
      key: 'less_than_1000_low_medium',
      isActive: false,
      value: {
        ...getManualFiltersFields(),
        priorityList: [
          highPriority(),
          mediumPriority({ value: true }),
          lowPriority({ value: true }),
        ],
        txAmountRange: {
          from: '',
          to: '1000',
        },
      },
    },
  ]
}

export { getManualFiltersFields, getSmartFiltersFields }
