export const essentialCurrencyList = [
  {
    "key": "opt",
    "label": "OPT",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in Optimism Explorer",
    "explorerTx": "https://optimistic.etherscan.io/{TYPE}/{VALUE}",
    "explorerAddr": "https://optimistic.etherscan.io/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "Optimism"
  },
  {
    "key": "matic",
    "label": "MATIC",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in Polygon Explorer",
    "explorerTx": "https://polygonscan.com/{TYPE}/{VALUE}",
    "explorerAddr": "https://polygonscan.com/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "Polygon"
  },
  {
    "key": "arb",
    "label": "ARB",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in Arbitrum Explorer",
    "explorerTx": "https://arbiscan.io/{TYPE}/{VALUE}",
    "explorerAddr": "https://arbiscan.io/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "Arbitrum"
  },
  {
    "key": "bnb",
    "label": "BNB",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in BscScan",
    "explorerTx": "https://bscscan.com/{TYPE}/{VALUE}",
    "explorerAddr": "https://bscscan.com/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "BNB"
  },
  {
    "key": "base",
    "label": "BASE",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in Base Explorer",
    "explorerTx": "https://basescan.org/{TYPE}/{VALUE}",
    "explorerAddr": "https://basescan.org/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "Base"
  },
  {
    "key": "glmr",
    "label": "GLMR",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in Moonbeam Explorer",
    "explorerTx": "https://moonscan.io/{TYPE}/{VALUE}",
    "explorerAddr": "https://moonscan.io/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "Moonbeam"
  },
  {
    "key": "ada",
    "label": "ADA",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in Cardano Explorer",
    "explorerTx": "https://cardanoscan.io/{TYPE}/{VALUE}",
    "explorerAddr": "https://cardanoscan.io/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "Cardano"
  },
  {
    "key": "avax",
    "label": "AVAX",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in Avalanche Explorer",
    "explorerTx": "https://snowtrace.io/{TYPE}/{VALUE}",
    "explorerAddr": "https://snowtrace.io/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "Avalanche"
  },
  {
    "key": "sol",
    "label": "SOL",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in Solana Explorer",
    "explorerTx": "https://explorer.solana.com/{TYPE}/{VALUE}",
    "explorerAddr": "https://explorer.solana.com/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "Solana"
  },
  {
    "key": "ton",
    "label": "TON",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in TON Explorer",
    "explorerTx": "https://tonscan.org/{TYPE}/{VALUE}",
    "explorerAddr": "https://tonscan.org/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "TON"
  },
  {
    "key": "dot",
    "label": "DOT",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in Polkadot Explorer",
    "explorerTx": "https://polkascan.io/{TYPE}/{VALUE}",
    "explorerAddr": "https://polkascan.io/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "Polkadot"
  },
  {
    "key": "ksm",
    "label": "KSM",
    "path": "ethVision",
    "baseUrl": "https://common.dev1.glprotocol.com/essential-api/",
    "addressRegex": ".*",
    "txRegex": ".*",
    "family": "eth",
    "explorerTitle": "Open in Kusama Explorer",
    "explorerTx": "https://kusama.subscan.io/{TYPE}/{VALUE}",
    "explorerAddr": "https://kusama.subscan.io/{TYPE}/{VALUE}",
    "decimals": 1,
    "profilesServiceUrl": "https://common.dev1.glprotocol.com/essential-api/api-labeling",
    "isNeedTokensCall": false,
    "essential": true,
    "blockchain": "Kusama"
  }
];

export const allBlockchainCoin = {
  "key": "all",
  "label": "All",
  "family": "all",
  "blockchain": "All networks" 
}